.content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: black;
}

.pages {
    display: flex;
    justify-content: space-between;
    padding: 12px;
    gap: 16px;
    padding-right: 24px;
    color: white;
}

.link {
    font-family: var(--heading-font);
    font-weight: 700;
    font-style: normal;
    text-decoration: none;
    color: white;
}

.link.active, .link :hover{
    text-decoration: underline;
    text-decoration-color: var(--primary-color);
    text-underline-offset: 3px;
    text-decoration-thickness: 3px;
}


.left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    padding: 12px;
}

.uzi {
    width: 50px;
    border-radius: 50px;
}

.name {
    font-family: var(--heading-font);
    font-weight: 900;
    font-style: normal;
    font-size: 48px;
    color: white;
}

@media only screen and (max-width: 600px) {
    .name {
        font-size: 20px;
    }
}