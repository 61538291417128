.uzi {
    width: 50%;
    border-radius: 150px;
    position: relative;
    transform: translate(10%, 0%);
}

img {
    border-radius: 18px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
}

.home {
    font-family: var(--body-font);
    font-weight: 400;
    font-size: 24px;
    width: 40%;
    text-align: center;
    color: white;
}

.intro {
    max-width: 450px;
    left: 50%;
    top: 50%;
    transform: translate(70%, -70%);
    padding: 20px;
    border-radius: 16px;
    color: white;
}

.intro2 {
    max-width: 450px;
    left: 50%;
    top: 50%;
    transform: translate(-20%, -140%);
    
    padding: 20px;
    border-radius: 16px;
    color: white;
}

.underline {
    text-decoration: underline;
    text-decoration-color: var(--primary-color);
    text-underline-offset: 3px;
    text-decoration-thickness: 5px;
}

@media only screen and (max-width: 600px) {
    .uzi {
        width: 100%;
        max-width: 200px;
        transform: translate(0%);
    }

    .intro, .intro2 {
        width: 100%;
        max-width: 300px;
        font-size: 1rem;
        transform: translate(0%,0%);
    }

    .content {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .underline {
        text-decoration-thickness: 2px;
    }
}