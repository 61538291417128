.link {
    font-family: var(--body-font);
    font-weight: 700;
    font-style: normal;
    text-decoration: none;
    color:white;
}

.link.active, .link :hover{
    text-decoration: underline;
    text-decoration-color: var(--primary-color);
    text-underline-offset: 3px;
    text-decoration-thickness: 3px;
}

.content {
    background-color: black;
}

.pages {
    display: flex;
    justify-content: center;
    padding: 12px;
    gap: 16px;
    padding-right: 24px;
}