@font-face {
    font-family: 'circular book';
    src: url('./Components/Fonts/SpaceMono-Regular.ttf');
}

@font-face {
    font-family: 'circular book bold';
    src: url('./Components/Fonts/SpaceMono-Bold.ttf');
}

@font-face {
    font-family: 'circular book bold italic';
    src: url('./Components/Fonts/SpaceMono-BoldItalic.ttf');
}

:root {
    --primary-color: #25adc5;
    --secondary-color: #acacac;
    --tertiary-color: #45bab5;
    --bold-font: 'circular book bold italic';
    --heading-font: 'circular book bold';
    --body-font: 'circular book';
    
}

::-webkit-scrollbar {
    display: none;
  }