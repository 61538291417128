.cannon {
    width:400px;
    position: relative;
    border-radius: 100px;
    transform: translate(-30%);
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
}

.about {
    font-family: var(--body-font);
    font-weight: 400;
    font-size: 24px;
    width: 40%;
    text-align: center;
    color: white;
}

.intro {
    left: 50%;
    top: 50%;
    transform: translate(60%, -40%);
    padding: 20px;
    border-radius: 16px;
    color: white;
}

.intro2 {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -60%);
    padding: 20px;
    border-radius: 16px;
    color: white;
}

@media only screen and (max-width: 600px) {

    .content {
        display: flex;
        align-items: center;
        flex-direction: column;

    }

    .intro, .intro2 {
        width: 100%;
        max-width: 300px;
        font-size: 1rem;
        transform: translate(0%);
        left: 100%;
        top: 100%;
    }
    .about {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .cannon {
        max-width: 250px;
        width: 100%;
        transform: translate(0%);
    }

}
