.fade-in-section {
    opacity: 0;
    transform: translateY(20%);
    transition: opacity 1s ease-out, transform 1s ease-out;
  }
  
  .is-visible {
    opacity: 1;
    transform: translateY(0%);
  }
  