.cardContainer {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  width: 100%;
  min-height: 300px;
  max-width: 250px;
  max-height: 400px;
  margin: 36px;
  display: flex;
  
}

.cardContainer:hover {
  transform: scale(1.05);
}

.cardContent {
  font-family: var(--body-font);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  color: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  /* overflow: hidden; */
  min-height: 100%;
  transition: transform 0.3s ease-in-out;
}