.content {
  background-color: black;
  font-family: var(--body-font);
  flex: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
  color: white
}


.description {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  flex: 1
}

.title {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.images {
  display: flex;
  align-items: center;
  justify-content: center;
}

img {
  padding: 24px;
  max-width: 500px;
}

.descriptionItem {
}
